import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'
import { ChartProps, IMarketOverviewState } from './types/MarketOverviewState.type'

const initialState: IMarketOverviewState = {}

const Slice = createSlice({
  name: '@module/markets/market-overview',
  initialState,
  reducers: {
    SetChartProps: (state, action: PayloadAction<ChartProps | undefined>) => {
      return {
        ...state,
        usmarket: {
          ...state.usmarket,
          chartProps: action.payload
        }
      }
    }
  }
})

export const { SetChartProps } = Slice.actions
export const getChartProps = (state: RootState) => state.modules.markets.marketoverview.usmarket?.chartProps
export default Slice.reducer
