import { MsalProvider } from '@azure/msal-react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'

import { reactPlugin } from './system/appinsights/AppInsights'
import { MsalInstance } from './system/msal/MsalInstance'
import { mui } from './system/environment/Config.json'
const { lisense } = mui
import { Provider } from 'react-redux'
import store from './store'
import { UUID } from './shared/utilities/Guid'
import { BrowserSessionKey, ContextJsonKey, ProfileJsonKey } from './shared/Constants'
import { LicenseInfo } from '@mui/x-license'
import { SetProfileContextKey, SetSimulationRequest } from './store/slice'
import { SimulationRequestType } from './store/types'
import { getJsonFromUrl } from './shared/utilities/UriUtil'
import ErrorBoundary from './shared/components/errorboundary/ErrorBoundary'

import '@fontsource-variable/inter'
import '@fontsource-variable/inter/wght.css'
import '@fontsource-variable/inter/wght-italic.css'
import './index.css'
import App from './App'

// Make window top and simulation check
const isWindowTop = window === window.top
let simulationRequest: SimulationRequestType | null | undefined = undefined
try {
  simulationRequest = JSON.parse(sessionStorage.getItem('simulation') ?? 'null')
  store.dispatch(SetSimulationRequest(simulationRequest))
} catch {
  sessionStorage.removeItem('simulation')
}

if (!simulationRequest && isWindowTop && window.location.search) {
  try {
    const params = getJsonFromUrl(window.location.search)
    const isSimulation = params?.['simulate'] === 'true'
    if (isSimulation) {
      simulationRequest = {
        simulationUser: params['simulationUser'],
        wsid: params?.['loginId'],
        pid: params?.['pid'],
        simulationAccount: params?.['sAcc']
      }
      sessionStorage.setItem('simulation', JSON.stringify(simulationRequest))
      store.dispatch(SetSimulationRequest(simulationRequest))
    }
  } catch {
    // do nothing
  }
}

;(async () => {
  //Enable below lines for mui datagrid lisense
  //const licenseStr = 'O=657333,E=1700201837011,S=premium,LM=perpetual,KV=2'
  //const encodedStr = `${md5(base64Encode(licenseStr))}${base64Encode(licenseStr)}`
  LicenseInfo.setLicenseKey(lisense)

  //This browser session key, will be used for tracking appinsights individual session,
  //to avoid resetting session id after refresh
  const sessionKey = sessionStorage.getItem(BrowserSessionKey)
  sessionStorage.setItem(BrowserSessionKey, sessionKey || UUID())

  //Create profile json and context json keys
  let contextKey = sessionStorage.getItem(ContextJsonKey)
  contextKey = contextKey || UUID()
  sessionStorage.setItem(ContextJsonKey, contextKey)

  let profileKey = sessionStorage.getItem(ProfileJsonKey)
  profileKey = profileKey || UUID()
  sessionStorage.setItem(ProfileJsonKey, profileKey)
  store.dispatch(SetProfileContextKey({ contextKey: contextKey, profileKey: profileKey }))
  // Msal Instance and set active account

  const msalInstance = MsalInstance.getInstance(!!simulationRequest)
  await msalInstance.initialize()
  const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />))
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <ErrorBoundary>
      <AppInsightsContext.Provider value={reactPlugin}>
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <main>
              <RouterProvider router={router} />
            </main>
          </Provider>
        </MsalProvider>
      </AppInsightsContext.Provider>
    </ErrorBoundary>
  )
})()
