import { combineReducers } from '@reduxjs/toolkit'
import MarketReducer from './MarketsSlice'
import NewsReducer from '../news/store/NewsSlice'
import WatchListReducer from '../watchlist/store/WatchListSlice'
import MarketOverviewReducer from '../overview/store/MarketOverviewSlice'
const MarketsReducer = combineReducers({
  market: MarketReducer,
  news: NewsReducer,
  watchlist: WatchListReducer,
  marketoverview: MarketOverviewReducer
})

export default MarketsReducer
